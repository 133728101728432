import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import HeaderImage from "../components/header-image"
import Page from "../components/page"
import Section from "../components/section"

const NotFound = () => {
  const data = useStaticQuery(graphql`
      {
          wpgraphql {
              pageBy(uri: "contact") {
                  id
                  contactPage {
                      headerImage {
                          sourceUrl
                          altText
                          imageFile {
                              childImageSharp {
                                  fluid (quality: 90) {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                      title
                      text
                  }
                  slug
                  seo {
                      title
                      metaDesc
                  }
              }
          }
      }
  `)
  let pageData = data.wpgraphql.pageBy.contactPage

  return(
    <Page
      seoTitle={ 'Page not found | iTouch Home' }
      seoDescription={ 'This page does not exist.' }
    >
      <HeaderImage
        image={ pageData.headerImage }
      />
      <Section>
        <h1>404 - Page not found</h1>
        <p>We're sorry, the page you requested could not be found. Please go to the homepage or contact us. </p>
      </Section>
    </Page>
  )
}
export default NotFound

